import { ControllerParams } from '@wix/yoshi-flow-editor';
import { createEventHandler } from '@wix/tpa-settings';
import { createMembersAreaWidgetPluginService } from '@wix/members-area-widget-plugin-lib/viewer';

import { createStore, getActionHandlers, Store } from '../../store';
import { localeThunk } from '../../store/slices';
import {
  ControllerContext,
  ControllerProps,
  FlowApi,
  Toast,
  ToastState,
  WixCodeApi,
} from '../../types';
import { withLocaleHeadersFactory } from './controllerUtils/localeHeaders';
import { AccountData } from '../../types/server';
import { getAccountData } from '../../server/account.api';
import { registerControllerListeners } from './controller-listeners';
import { ProfileInfoChangeSubject } from '../../viewer.app';
import { SettingsEvent } from '../../types/appSettings';

export const getControllerContext = ({
  flowAPI,
  controllerConfig: { setProps, wixCodeApi },
  appData,
}: ControllerParams): ControllerContext => {
  const profileInfoChangeSubject = appData?.profileInfoChangeSubject as
    | ProfileInfoChangeSubject
    | undefined;
  const settingsListener = createEventHandler<SettingsEvent>({});
  const widgetPluginService = createMembersAreaWidgetPluginService();

  return {
    flowAPI,
    wixCodeApi,
    setProps,
    profileInfoChangeSubject,
    settingsListener,
    widgetPluginService,
  };
};

const getControllerProps = (flowAPI: FlowApi, store: Store) => {
  const state = store.getState();
  const actionHandlers = getActionHandlers(store);
  const props: ControllerProps = {
    ...state,
    ...actionHandlers,
    ...(flowAPI.controllerConfig.platformAPIs.bi?.viewerName ===
      'thunderbolt' && { fitToContentHeight: true }),
  };

  return props;
};

const getInitialState = async (wixCodeApi: WixCodeApi, flowAPI: FlowApi) => {
  const withLocaleHeaders = withLocaleHeadersFactory(flowAPI);
  const { isEditor } = flowAPI.environment;
  const { data } = await flowAPI.httpClient.request<AccountData>(
    withLocaleHeaders(getAccountData({ isEditor })),
  );

  const { member, fields, site } = data;

  return {
    fields,
    member,
    site: {
      baseUrl: wixCodeApi.location.baseUrl,
      isSocial: site?.isSocial ?? false,
      showPublicSections: site?.showPublicSections ?? false,
    },
    ui: {
      toast: {
        isVisible: false,
        type: Toast.NONE,
        options: {},
      } as ToastState,
      slug: {
        isLoading: false,
        isAvailable: true,
      },
      dropdownOptions: {
        country: [],
        state: [],
      },
      shouldOpenBlockedModal:
        wixCodeApi.location.query.blockedMembersModal === 'true',
      changePasswordState: { stage: 'initial' as const },
      changeEmailState: { stage: 'initial' as const },
    },
  };
};

export const initializeMyAccountWidget = async ({
  wixCodeApi,
  flowAPI,
  setProps,
  settingsListener,
  profileInfoChangeSubject,
}: ControllerContext) => {
  if (wixCodeApi.user.currentUser.loggedIn === false) {
    flowAPI.sentry?.captureMessage('User not logged in');
    return;
  }

  const store = createStore(
    {
      flowAPI,
      wixCodeApi,
      profileInfoChangeSubject,
    },
    await getInitialState(wixCodeApi, flowAPI),
  );
  store.subscribe(() => setProps({ ...store.getState() }));

  const props = getControllerProps(flowAPI, store);
  setProps(props);
  store.dispatch(localeThunk.fetchLocaleData());
  await registerControllerListeners(flowAPI, settingsListener, store);
};
